var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.canViewChat || _vm.getInfoErrorMessage ? _c('div', {
    staticClass: "text-center"
  }, [_c('no-data', {
    attrs: {
      "first-text": "No chat to show",
      "second-text": "It seems that you don't have the permission to view the chat",
      "with-button": ""
    }
  })], 1) : _c('div', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [!_vm.fromShare ? _c('div', {
    staticClass: "font-size-h3 bold-text"
  }, [_vm._v(" Chat "), _c('v-chip', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" BETA ")])], 1) : _vm._e(), _c('div', {
    staticClass: "bold-text text-body-1"
  }, [_vm._v(" Discuss with your team about app releases, issues, achievements or weather! ")])])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "background"
  }, [_c('v-row', {
    staticClass: "scrollable",
    attrs: {
      "id": "messageBox"
    },
    on: {
      "scroll": _vm.getScrollValue
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.loading ? _c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-two-line"
      }
    })], 1);
  }), 1) : _vm._e(), !_vm.loading && _vm.getAppMessages.length ? _c('div', [_vm.moreData ? _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.getAppMessages, function (detail, index) {
    var _detail$user, _detail$user2, _detail$user3, _detail$user4, _detail$user5;
    return _c('v-row', {
      key: index,
      attrs: {
        "justify": _vm.currentUser.id === (detail === null || detail === void 0 ? void 0 : (_detail$user = detail.user) === null || _detail$user === void 0 ? void 0 : _detail$user.id) ? 'end' : 'start'
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": _vm.currentUser.id == (detail === null || detail === void 0 ? void 0 : (_detail$user2 = detail.user) === null || _detail$user2 === void 0 ? void 0 : _detail$user2.id) ? 'end' : 'start'
      }
    }, [_vm.currentUser.id !== (detail === null || detail === void 0 ? void 0 : (_detail$user3 = detail.user) === null || _detail$user3 === void 0 ? void 0 : _detail$user3.id) ? _c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('ProfileImage', {
      attrs: {
        "size": "30",
        "image": detail === null || detail === void 0 ? void 0 : (_detail$user4 = detail.user) === null || _detail$user4 === void 0 ? void 0 : _detail$user4.image,
        "alt": detail === null || detail === void 0 ? void 0 : (_detail$user5 = detail.user) === null || _detail$user5 === void 0 ? void 0 : _detail$user5.name
      }
    })], 1) : _vm._e(), _c('v-col', {
      staticStyle: {
        "float": "right"
      },
      attrs: {
        "cols": _vm.$vuetify.breakpoint.smAndDown ? '9' : 'auto'
      }
    }, [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var _detail$user6, _detail$user7;
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-card', _vm._g({
            staticClass: "pa-2",
            class: _vm.currentUser.id == (detail === null || detail === void 0 ? void 0 : (_detail$user6 = detail.user) === null || _detail$user6 === void 0 ? void 0 : _detail$user6.id) ? _vm.getActiveClass : _vm.getInactiveClass,
            attrs: {
              "flat": "",
              "outlined": _vm.$vuetify.theme.dark,
              "box": ""
            }
          }, _vm.currentUser.id == detail.user.id ? on : ''), [_c('div', [_c('span', {
            staticClass: "darkGrey--text"
          }, [_vm._v(" " + _vm._s(_vm.currentUser.id === (detail === null || detail === void 0 ? void 0 : (_detail$user7 = detail.user) === null || _detail$user7 === void 0 ? void 0 : _detail$user7.id) ? "" : detail.user.name) + " ")]), _c('span', {
            staticClass: "caption"
          }, [_c('vue-hoverable-date', {
            attrs: {
              "date": detail.created_at
            }
          })], 1)]), _c('div', {
            staticClass: "py-2 font14",
            staticStyle: {
              "white-space": "pre-line"
            }
          }, [_vm._v(" " + _vm._s(detail.message) + " ")])])];
        }
      }], null, true)
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.onDeleteClick(detail);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "iconColor",
        "small": "",
        "left": ""
      }
    }, [_vm._v("delete")]), _vm._v("Delete ")], 1)], 1)], 1), _vm.showEditForm && _vm.editContent.id == detail.id ? _c('v-card', {
      staticClass: "pa-2 py-3"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-textarea', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|max:600',
        expression: "'required|max:600'"
      }],
      attrs: {
        "filled": "",
        "auto-grow": "",
        "rows": "2",
        "counter": "600"
      },
      model: {
        value: _vm.editContent.message,
        callback: function callback($$v) {
          _vm.$set(_vm.editContent, "message", $$v);
        },
        expression: "editContent.message"
      }
    }, 'v-textarea', _vm.veeValidate('Message', 'Message'), false))], 1), _c('v-col', {
      staticClass: "pt-0 text-right",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "text": "",
        "color": "primary",
        "disabled": _vm.busy
      },
      on: {
        "click": _vm.cancelEdit
      }
    }, [_vm._v("Cancel")]), _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "color": "primary",
        "depressed": "",
        "outlined": _vm.$vuetify.theme.dark,
        "loading": _vm.busy
      },
      on: {
        "click": _vm.editMessage
      }
    }, [_vm._v("Save")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);
  })], 2) : _vm._e(), !_vm.loading && !_vm.getAppMessages.length ? _c('v-row', {
    staticClass: "mt-15",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-10",
    attrs: {
      "align-self": "center",
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No Messages Yet",
      "second-text": "<p>Each app has its dedicated chat. Start the conversation with your team about this app.<br /><br />Only those with access to the app and chat permissions can join in. Accessible right from our app, it's a secure space to collaborate and share insights!</p>"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "py-3",
    class: _vm.$vuetify.theme.dark ? 'lightBackground ' : 'offWhite'
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('emoji-picker', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "emoji": _vm.insert
    },
    scopedSlots: _vm._u([{
      key: "emoji-invoker",
      fn: function fn(_ref2) {
        var clickEvent = _ref2.events.click;
        return [_c('div', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return clickEvent.apply(null, arguments);
            }
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "darkGrey"
          },
          domProps: {
            "textContent": _vm._s('mdi-emoticon-happy-outline')
          }
        })], 1)], 1)];
      }
    }, {
      key: "emoji-picker",
      fn: function fn(_ref3) {
        var emojis = _ref3.emojis;
        return _c('v-card', {
          staticClass: "emoji-picker pt-1 pa-3"
        }, [_c('div', [_c('div', _vm._l(emojis, function (emojiGroup, category) {
          return _c('div', {
            key: category
          }, [_c('div', {
            staticClass: "bold-text font-size-h3"
          }, [_vm._v(_vm._s(category))]), _c('div', {
            staticClass: "emojis"
          }, _vm._l(emojiGroup, function (emoji, emojiName) {
            return _c('span', {
              key: emojiName,
              staticClass: "cursor-pointer",
              attrs: {
                "title": emojiName
              },
              on: {
                "click": function click($event) {
                  return _vm.insert(emoji);
                }
              }
            }, [_vm._v(_vm._s(emoji))]);
          }), 0)]);
        }), 0)])]);
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10",
      "md": "11"
    }
  }, [_c('v-textarea', {
    key: _vm.resetKey,
    attrs: {
      "placeholder": "Type your message here...",
      "rounded": "",
      "auto-grow": "",
      "counter": "600",
      "outlined": "",
      "hide-details": "",
      "rows": "1",
      "row-height": "15",
      "dense": "",
      "append-icon": "mdi-send"
    },
    on: {
      "click:append": _vm.sendFeedback,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return _vm.sendFeedback.apply(null, arguments);
      }
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }